import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import {
  API_GET_MASTER_INFO,
  API_MANAGE_MASTER,
  API_PUT_MASTER_RESPONSIBLE
} from './urls';
import { ManageMastersUrls, MastersPanelKeys } from '../../domain/MastersPanel';
import { IMasterRepository } from '../../domain/IMastersRepository';
import UploadMasterResponseDTO from 'modules/inbound/maestros/masters/infrastructure/dto/UploadMasterResponseDTO';
import { mastersDataDTOMapper } from '../dto/mastersDataDTOMapper';
import { mastersDataMapper } from '../../domain/mastersDataMapper';

class MastersRepository extends BaseRepository implements IMasterRepository {
  private readonly _api: IApi;
  private readonly _apiGetMastersInfo: string;
  private readonly _apiManageMaster: (
    manageMasterUrl: ManageMastersUrls
  ) => string;
  private readonly _apiupdateMasterResponsible: string;

  constructor({
    api,
    apiManageMaster,
    apiGetMastersInfo,
    apiupdateMasterResponsible
  }) {
    super();
    this._api = api;
    this._apiGetMastersInfo = apiGetMastersInfo;
    this._apiManageMaster = apiManageMaster;
    this._apiGetMastersInfo = apiGetMastersInfo;
    this._apiupdateMasterResponsible = apiupdateMasterResponsible;
  }

  getMastersInfo() {
    return this._api
      .get(this._apiGetMastersInfo)
      .then((res) => mastersDataDTOMapper(res))
      .then((dto) => mastersDataMapper(dto));
  }

  downloadMaster({
    queryParams,
    manageMasterUrl
  }: {
    queryParams: object;
    manageMasterUrl: ManageMastersUrls;
  }) {
    const url = `${this._apiManageMaster(
      manageMasterUrl
    )}/download${this._createQueryParams(queryParams)}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }

  uploadMaster(file: object, manageMasterUrl: ManageMastersUrls) {
    return this._api
      .post(`${this._apiManageMaster(manageMasterUrl)}/upload`, { file: file })
      .then(
        (res) =>
          new UploadMasterResponseDTO(
            res || {
              detail: null,
              error: null,
              message: null
            }
          )
      );
  }

  updateMasterResponsible({
    body
  }: {
    body: {
      master_name: MastersPanelKeys;
      name: string;
      email: string;
      affected_panels: number[];
    };
  }) {
    const url = `${this._apiupdateMasterResponsible}`;
    return this._api.put(url, body).then((res) => res);
  }
}

export default new MastersRepository({
  api: api,
  apiGetMastersInfo: API_GET_MASTER_INFO,
  apiManageMaster: API_MANAGE_MASTER,
  apiupdateMasterResponsible: API_PUT_MASTER_RESPONSIBLE
});

export { MastersRepository };
